@import "input";
@import "number-input";
@import "textarea";
@import "select";
@import "checkbox";
@import "datepicker";
@import "label";
@import "slide-toggle";
@import "autocomplete";
@import "options";
@import "combobox";
@import "inline-edit";
@import "hint";
@import "chips-input";

.adxad-form-field {
  display: block;
  position: relative;
}

.form-group {
  margin-bottom: 16px;
}

.form-group {
  margin-bottom: 16px;
}

.adxad-error {
  font-size: 11px;
  padding-top: 4px;
  color: $errorColor;
}

.fields-group {
  display: flex;
  align-items: center;
  border: $inputBorder;
  border-radius: 8px;
  overflow: hidden;

  &__item {
    flex: 1;

    & + & {
      border-left: $inputBorder;
    }
  }
}
