@import "media.mixins";
@import "media-query";

$endpointXl: "1200px";

.adxad-paginator {
  position: sticky;
  left: 0;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include media("max-width", $endpointXl) {
    grid-template-columns: repeat(1, 1fr);
  }

  @include for-size(mobile) {
    gap: 8px;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0 auto;
  }

  &__arrow,
  &__page {
    font-size: 13px;
    font-weight: bold;
    color: $textDarkGray;
    margin: 0 4px;
    cursor: pointer;
    min-width: 40px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: color 0.15s ease-in-out;

    @include for-size(mobile) {
      margin: 0 2px;
      min-width: 32px;
    }

    &:not(.more):not(.disabled):hover {
      color: $hoverColor;
    }

    &.disabled {
      color: $disableText;
      cursor: default;
    }
  }

  &__page {
    font-size: 13px;
    transition: background-color 0.1s ease-in;

    &.more:hover {
      cursor: default;
    }

    &:not(.disabled).selected {
      color: $hoverColor;
      cursor: default;
    }
  }

  &__items-select {
    display: flex;
    align-items: center;

    @include for-size(mobile) {
      justify-content: center;
    }
  }

  &__items-select-text {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    color: $textGray;
  }

  &__range {
    display: flex;
    color: $textGray;
    font-size: 12px;
    margin: 0 0 0 16px;

    &.hide {
      display: none;
    }
  }

  &__field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    margin-left: 0;

    @include media("max-width", $endpointXl) {
      flex-grow: 1;
      justify-content: end;
    }

    @include for-size(mobile) {
      justify-content: center;
    }
  }

  &__field-text {
    color: $textGray;
    font-size: 12px;
    margin-right: 10px;
  }

  &__field-action {
    background: #eaecf2;
    border-radius: 8px;
    box-sizing: border-box;
    border: none;
    height: 32px;
    font-weight: bold;
    font-size: 13px;
    color: $textDarkGray;
    margin-left: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    &:disabled {
      background: #eee;
      color: #bdbdbd;
      cursor: default;
    }
  }

  &__input {
    background: #fff;
    border: $lightBtnBorder;
    border-radius: 8px;
    height: 32px;
    width: 80px;
    font-size: 13px;
    color: $black;
    font-weight: 500;
    line-height: 1;
    transition: all 0.15s ease-in-out;
    padding: 4px 16px;
    font-family: $baseFontFamily;

    &:focus {
      outline: none;
    }

    &:disabled {
      background: #eee;
    }
  }
}

.adxad-paginator-select {
  position: relative;

  &__field {
    cursor: pointer;
    border: $lightBtnBorder;
    border-radius: 8px;
    height: 32px;
    width: 80px;
    padding: 4px 12px 4px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    color: #474f5c;

    &.disabled {
      background: #eee;
      color: #bdbdbd;
      cursor: default;
    }
  }

  &__num {
    font-size: 13px;
  }

  &__label {
    position: absolute;
    left: 12px;
    top: 16px;
    font-size: 16px;
    color: $blue600;
    transition: all 0.15s ease-in-out;
    pointer-events: none;
    line-height: 1;
  }
}

.adxad-paginator-options {
  position: absolute;
  z-index: 10000;
  width: 90px;

  &__wrapper {
    overflow-y: auto;
    max-height: 256px;
    position: relative;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 8px 0;
    background: #fff;
    box-shadow: $shadow;
    transition:
      opacity 0.15s ease-in-out,
      top 0.15s ease-in-out,
      bottom 0.15s ease-in-out;
    opacity: 0;

    &.open {
      opacity: 1;
    }

    .hidden & {
      display: none;
    }
  }

  &_open-on-bottom &__wrapper {
    top: 16px;

    &.open {
      top: 0;
    }
  }

  &_open-on-top &__wrapper {
    bottom: 16px;

    &.open {
      bottom: 0;
    }
  }

  &__option {
    display: block;
    width: 100%;
    cursor: pointer;
    color: $black;
    font-size: 13px;
    padding: 12px 24px;
    word-break: break-all;

    &:hover,
    &.hover {
      background: #f7f8fa;
    }
  }

  &__mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
