@mixin for-size($size) {
  @if $size == mobile {
    @media (max-width: 599px) and (min-width: 1px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 899px) and (min-width: 600px) {
      @content;
    }
  }
}
